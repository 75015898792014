<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>카지노&Slot</sub-title>
                <div class="main_panel">
                    <div class="casino_panel">
                        <div class="casino_re_panel">
                            <div class="p1">
                                카지노 충전
                            </div>
                            <div class="p2" style="text-align: center">
                                <span style="width: 100%;height: 28px;line-height: 28px">보유머니 전체를 전환합니다</span>
                            </div>
                            <div class="p3">
                                <button class="btn-change cbg1" @click="change2casinocash()">카지노칩 전환</button>
                            </div>
                        </div>
                        <div class="casino_re_panel">
                            <div class="p1">
                                <i class="fa fa-dot-circle-o" style="color: #bc955b"></i> 카지노 환전
                            </div>
                            <div class="p2" style="text-align: center">
                                <span style="width: 100%;height: 28px;line-height: 28px">카지노칩 전체를 전환합니다</span>
                            </div>
                            <div class="p3">
                                <button class="btn-change cbg2" @click="change2sportsmoney()">보유머니 전환</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="cs_panel_button">
                    <button class="btn-casino" :class="{'btn-cs-active':type==1}"
                            @click="change(1)">카지노
                    </button>
                    <button class="btn-slot" :class="{'btn-cs-active':type==2}"
                            @click="change(2)">슬롯
                    </button>
                </div>

                <div class="casino_panel" v-if="type==1">
                    <div class="casino">
                        <div @click="openGame(HONOR_EVO_GAMEID,HONOR_EVO_VENDOR,3)">
                            <img src="../../assets/images/casino/01.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_DREAM_GAMEID,HONOR_DREAM_VENDOR,3)">
                            <img src="../../assets/images/casino/02.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_ASIAGAME_GAMEID,HONOR_ASIAGAME_VENDOR,3)">
                            <img src="../../assets/images/casino/03.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_PRAGMATIC_GAMEID,HONOR_PRAGMATIC_VENDOR,3)">
                            <img src="../../assets/images/casino/04.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_BOTA_GAMEID,HONOR_BOTA_VENDOR,3)">
                            <img src="../../assets/images/casino/05.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_WM_GAMEID,HONOR_WM_VENDOR,3)">
                            <img src="../../assets/images/casino/06.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_DOWIN_GAMEID,HONOR_DOWIN_VENDOR,3)">
                            <img src="../../assets/images/casino/07.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_MICRO_GAMEID,HONOR_MICRO_VENDOR,3)">
                            <img src="../../assets/images/casino/08.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_SKYWIND_GAMEID,HONOR_SKYWIND_VENDOR,3)">
                            <img src="../../assets/images/casino/09.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_PLAYTECH_GAMEID,HONOR_PLAYTECH_VENDOR,3)">
                            <img src="../../assets/images/casino/10.jpg" alt="">
                        </div>
                    </div>

                </div>
                <div class="casino_panel" v-if="type == 2">
                    <div class="slot-btns">
                        <!--                        <button class="btn-slot-comp bw4" v-for="(item,index) in slotComp"-->
                        <!--                                @click="getGameListByCode(item.code)">-->
                        <!--                            {{item.name}}-->
                        <!--                        </button>-->
                        <img src="../../assets/images/casino/sloat-evoplay.jpg" @click="getGameListByCode('EVOPLAY')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-plagmatic.jpg"
                             @click="getGameListByCode('PragmaticPlay')" alt="">

                        <img src="../../assets/images/casino/sloat-hanebaro.jpg" @click="getGameListByCode('Habanero')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-cq9.jpg" @click="getGameListByCode('CQ9')" alt="">
                        <img src="../../assets/images/casino/sloat-playstar.jpg" @click="getGameListByCode('PlayStar')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-gameart.jpg" @click="getGameListByCode('GameArt')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-relax.jpg" @click="getGameListByCode('Relax Gaming')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-boongo.jpg" @click="getGameListByCode('Booongo')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-playson.jpg" @click="getGameListByCode('PlaySon')"
                             alt="">
                        <img src="../../assets/images/casino/sloat-nolimit.jpg" @click="getGameListByCode('Nolimit City')"
                             alt="">
                    </div>
                    <div class="search">
                        <input type="text" v-model="searchVal" @input="search" placeholder="슬롯 2글자 이상 검색">
                    </div>
                    <div class="slot_game">
                        <div class="slot-item" v-for="(item,index) in slotGameList" :key="index"
                             @click="openGame(item.id,item.vendor,4)" v-if="item.isshow">
                            <img :src="item.thumbnail" alt="">
                            <span class="name">{{item.langs['ko']}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--위로가기-->
        <scroll-top-comp></scroll-top-comp>
        <foot-comp></foot-comp>
        <mobile-foot-menus></mobile-foot-menus>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import SubTitle from "../../components/SubTitle";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {
        addMemberCasinoCash, addMemberCasinoCash2,
        getAccountBalance, getAccountBalance2, getCasinoMaintaining, getGameLancher, getGameLancher2,
        getGameList, subjectMemberCasinoCash, subjectMemberCasinoCash2
    } from "../../network/casinoHonorRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import MobileFootMenus from "../../components/MobileFootMenus";

    export default {
        name: "CasinoSlotGameHonor",
        mixins: [postionMixin],
        components: {
            MobileFootMenus,
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                slotComp: [
                    {'code': 'EVOPLAY', 'name': '에보플레이', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {'code': 'PragmaticPlay', 'name': '프로그매틱', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {'code': 'Booongo', 'name': '부운고', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {'code': 'Habanero', 'name': '하바네로', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {'code': 'CQ9', 'name': 'CQ9', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {'code': 'PlayStar', 'name': '플레이 스타', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {'code': 'GameArt', 'name': '게임아트', 'img': '../../assets/images/casino/sloat-evoplay.jpg'},
                    {
                        'code': 'Relax Gaming',
                        'name': 'Relax Gaming',
                        'img': '../../assets/images/casino/sloat-evoplay.jpg'
                    },
                ],

                currentSlotCode: 'EVOPLAY',
                slotGameList: [],
                slotGameListEVO: [],
                slotGameListPLAG: [],
                slotGameListBOONGO: [],
                slotGameListHABANERO: [],
                slotGameListCQ9: [],
                slotGameListPLAYSTAR: [],
                slotGameListGAMEART: [],
                slotGameListRELAX: [],
                sportsConst,
                casinocash: 0,
                position: "Casino",
                searchVal: '',
                type: 1,
                HONOR_PROVIDER_QX : "QX",
                HONOR_PROVIDER_UX : "UX",
                HONOR_PROVIDER_CX : "CX",
                HONOR_PROVIDER_BO : "BO",

                HONOR_EVO_VENDOR : "evolution",
                HONOR_EVO_GAMEID : "evolution_baccarat_sicbo",

                HONOR_DREAM_VENDOR : "DreamGame",//CX
                HONOR_DREAM_GAMEID : "dgcasino",//CX

                HONOR_ASIAGAME_VENDOR : "Asia Gaming", //CX
                HONOR_ASIAGAME_GAMEID : "B001", //CX

                HONOR_PRAGMATIC_VENDOR : "PragmaticPlay Live",//CX
                HONOR_PRAGMATIC_GAMEID : "401",//CX

                HONOR_BOTA_VENDOR : "bota",//bo
                HONOR_BOTA_GAMEID : "bota",//bo

                HONOR_WM_VENDOR : "WM Live",//bo
                HONOR_WM_GAMEID : "wmcasino",//bo

                HONOR_DOWIN_VENDOR : "dowinn",//bo
                HONOR_DOWIN_GAMEID : "dowinncasino",//bo


                HONOR_MICRO_VENDOR : "MicroGaming Plus",//bo
                HONOR_MICRO_GAMEID : "SMG_titaniumLiveGames_MP_Baccarat",//bo

                HONOR_SKYWIND_VENDOR : "Skywind Live",//bo
                HONOR_SKYWIND_GAMEID : "sw_ro_spbac",//bo

                HONOR_PLAYTECH_VENDOR : "PlayTech",//bo
                HONOR_PLAYTECH_GAMEID : "ptx_ubal_lobby",//bo
            }
        },
        methods: {
            change(type) {
                this.type = type
            },
            search() {
                if (this.searchVal === '') {
                    this.slotGameList.forEach(g => {
                        this.$set(g, 'isshow', true)
                    });
                } else {

                    this.slotGameList.forEach(g => {
                        if (g['langs'].ko.indexOf(this.searchVal) !== -1) {
                            this.$set(g, 'isshow', true)
                        } else {
                            this.$set(g, 'isshow', false)
                        }

                    });
                }

                console.log()
            },
            openGame(gameId, vendor,gtype) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getGameLancher(gameId, vendor,gtype).then(res => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            getGameListByCode(vendor) {
                this.$store.commit(RECEIVE_SHOW_LOADING)

                getGameList(vendor).then(res => {
                    if (res.data.success) {
                        this.slotGameList = JSON.parse(res.data.data);
                        this.slotGameList.forEach(g => {
                            this.$set(g, 'isshow', true)
                        });
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true,
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                });
            },
            change2casinocash() {
                if (this.casinocash > this.$store.state.userInfo.cash) {
                    this.$swal({
                        title: '보유금액 ' + this.$store.state.userInfo.cash + '원을 초과하였습니다',
                        type: 'error',
                        confirmButtonText: '확인',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                addMemberCasinoCash(this.casinocash).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            title: '전환성공',
                            type: 'success',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.casinocash = 0;
                        this.$store.dispatch('actionUserInfo')
                        setTimeout(() => {
                            this.getCasinoBalance();
                        }, 1300)

                    } else {
                        this.$swal({
                            title: '전환실패, ' + res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            change2sportsmoney() {
                this.$swal({
                    title: '보유머니로 전환 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        subjectMemberCasinoCash().then((res) => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '전환성공',
                                    type: 'success',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.casinocash = 0;
                                this.$store.dispatch('actionUserInfo')
                                setTimeout(() => {
                                    this.getCasinoBalance();
                                }, 1300)
                            } else {
                                this.$swal({
                                    title: '전환실패, ' + res.data.msg,
                                    type: 'error',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }

                        })
                    }
                })

            },
            getCasinoBalance(){
                getAccountBalance().then(res => {
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            },

            change2sportsmoney2() {
                this.$swal({
                    title: '보유머니로 전환 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        subjectMemberCasinoCash2().then((res) => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '전환성공',
                                    type: 'success',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.slot = 0;
                                this.$store.dispatch('actionUserInfo')
                                setTimeout(() => {
                                    this.getCasinoBalance2();
                                }, 1300)
                            } else {
                                this.$swal({
                                    title: '전환실패, ' + res.data.msg,
                                    type: 'error',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }

                        })
                    }
                })

            },
            change2casinocash2() {
                if (this.slotcash > this.$store.state.userInfo.cash) {
                    this.$swal({
                        title: '보유금액 ' + this.$store.state.userInfo.cash + '원을 초과하였습니다',
                        type: 'error',
                        confirmButtonText: '확인',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$swal({
                    title: '슬롯칩으로 전환 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        addMemberCasinoCash2(this.slotcash).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '전환성공',
                                    type: 'success',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.casinocash = 0;
                                this.$store.dispatch('actionUserInfo')
                                setTimeout(() => {
                                    this.getCasinoBalance2();
                                }, 1300)

                            } else {
                                this.$swal({
                                    title: '전환실패, ' + res.data.msg,
                                    type: 'error',
                                    confirmButtonText: '확인',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })

            },
            getCasinoBalance2() {
                getAccountBalance2().then(res => {
                    this.$set(this.$store.state.userInfo, 'slotcash', res.data.data)
                })
            },

        },
        created() {
            if (this.$store.state.isLogin) {
                if(this.$route.query.type){
                    this.type = this.$route.query.type
                    this.change(this.type)
                }
                getCasinoMaintaining().then(res => {
                    if (res.data.success) {
                        if (!res.data.data) {
                            this.$swal({
                                title: '카지노 점검중입니다',
                                type: 'error',
                                confirmButtonText: '확인',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.getGameListByCode(this.currentSlotCode);
                            if (this.$store.state.isLogin) {
                                this.getCasinoBalance()
                            }
                        }
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .casino_panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
    }

    .casino_panel .casino_re_panel {
        width: 49.6%;
        height: 160px;
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--noticeBg);
    }

    .casino_panel .casino_re_panel .p1 {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
    }

    .casino_panel .casino_re_panel .p2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        text-align: center;
        padding: 10px 0;
        color: #fff9ff;
        font-size: 14px;
        font-weight: 600;
    }

    .casino_panel .casino_re_panel .p2 .t1 {
        text-align: center;
        width: 30%;
    }

    .casino_panel .casino_re_panel .p2 .t2 {
        text-align: center;
        width: 40%;
    }

    .t2 input {
        width: 100%;
        text-align: center;
        background-color: #ffffff;
        height: 28px;
        color: #0b1423;
    }

    .casino_panel .casino_re_panel .p2 .t3 {
        text-align: center;
        width: 30%;
    }

    .casino_panel .casino_re_panel .p2 .t3 button {
        height: 28px;
        padding: 0 3px;
        border-radius: 1px;
    }

    .casino_panel .casino_re_panel .p2 .btn-all {
        border: 2px solid var(--btn01Bg);
    }

    .casino_panel .casino_re_panel .p2 .btn-reset {
        background-color: var(--btn01Bg);
        color: #ffffff;
        margin-left: 5px;
    }

    .casino_panel .casino_re_panel .p3 {
        text-align: center;
    }

    .casino_panel .casino_re_panel .btn-change {
        height: 36px;
        padding: 0 16px;
        border-radius: 1px;
        color: #353535;
        font-weight: 600;
    }
    .casino_panel .casino_re_panel .cbg1{
        background: linear-gradient(180deg, #fffd4f, #fffd70) !important;
    }
    .casino_panel .casino_re_panel .cbg2{
        background: linear-gradient(180deg, #ac7eff, #c09eff) !important;
    }

    .casino_panel .slot-btns {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .casino_panel .slot-btns img {
        cursor: pointer;
        width: 18%;
        --max-width: 180px;
        margin: 10px;
    }
    .casino_panel .slot-btns img:hover {
        --opacity: .7;
        box-shadow: 0 0 10px rgba(53, 206, 0, 0.6);
        transition: left 0.6s ease; /* left 속성에 트랜지션 적용 */

    }

    .casino_panel .btn-slot-comp {
        background-color: var(--btn01Bg);
        margin-bottom: 2px;
        height: 32px;
        line-height: 32px;
        border-radius: 1px;
        color: #fff9ff;
        font-weight: normal;

    }


    .casino_panel .search {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .casino_panel .search input {
        color: #0c0e0e;
        background-color: #cfcfd1;
        height: 32px;
        text-align: center;
    }

    .cs_panel_button {
        margin: 20px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-casino {
        border-radius: 1px;
        padding: 10px 0;
        width: 49%;
        background-color: var(--sportsKindButtonBg1);
        color: #fff9ff;
        margin: 0 2px;
    }

    .btn-slot {
        --border: 1px solid var(--loginBtnBg);
        border-radius: 1px;
        padding: 10px 0;
        width: 49%;
        margin: 0 2px;
        background-color: var(--sportsKindButtonBg1);
    }

    .btn-cs-active {
        background: linear-gradient(180deg, var(--sportsKindButtonBgActive1), var(--sportsKindButtonBgActive2)) !important;
        color: #fff9ff;
        font-weight: 600;
    }

    @media screen and (max-width: 1024px) {
        .casino_panel .casino_re_panel {
            width: 100% !important;
            margin-top: 5px!important;
        }

    }

    .button_group a {
        margin-top: 3px;
    }

    .casino_panel .casino {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .casino_panel .casino div {
        width: 19.5%;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
    }


    .casino_panel .casino {
        max-width: 100%;
        cursor: pointer;
    }

    .casino img {
        width: 100%;
        border: 1px solid var(--casinoimgbordercolor);
        border-radius: 1px;
    }
   .casino img:hover {
       opacity: .8;
       box-shadow: 0 0 7px rgba(53, 206, 0, 0.3);
       transition: left 0.6s ease; /* left 속성에 트랜지션 적용 */
    }

    .casino_panel .slot_company {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px auto;
    }


    .casino_panel .slot_game {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 10px;
    }

    .casino_panel .slot_game .slot-item {
        min-width: 150px;
        max-width: 200px;
        border-bottom: 1px solid #4a4a4a;
        cursor: pointer;
        margin: 5px 5px;
    }

    .casino_panel .slot_game .name {
        display: block;
        line-height: 50px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #585858;
    }

    .casino_panel .slot_game img {
        width: 100%;
        border: 1px solid #f4c600;
        border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
        .casino div {
            width: 49.5% !important;
            box-sizing: border-box;
            padding: 10px 10px;
        }
        .casino_panel .slot_game div {
            width: 45.5%;
        }

        .casino_panel .slot_company a {
            width: 48% !important;
        }

        .casino_panel .board_box {
            width: 100% !important;
        }
        .casino_panel .slot-btns img{
            width: 40% !important;
        }

    }
</style>